import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Button,
  Notice,
  DescriptionTable,
  Notification,
} from 'shared/components';
import { deactivateHubSpotIntegration } from 'src/account/actions';
import { maskString, sendErrorReport } from 'shared/helpers';
import HubSpotInit from './HubSpotInit';
import { ReactComponent as HubSpotLogo } from './hubspot-logo.svg';

const HubSpot = ({
  integrations,
}) => {
  const hubSpotIntegration = integrations.find(i => i.code === 'hubspot');
  const isActive = get(hubSpotIntegration, 'is_active');

  const [isFormDisplayed, setFormDisplay] = useState(false);
  const [isDeactivating, setDeactivating] = useState(false);

  const handleDeactivate = () => {
    const baseUrl = window.appConfig.integrationServiceBaseUrl;
    setDeactivating(true);

    const data = {
      licenseSpringApiKey: get(hubSpotIntegration, 'data.licenseSpringApiKey'),
    };
    deactivateHubSpotIntegration(baseUrl, data)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setDeactivating(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
        sendErrorReport(err);
      });
  };

  if (!isActive) {
    return (
      <div className="Integrations">
        <div className="row">
          <HubSpotLogo width="120px" height="60px" />
          <div className="docs-link">
            <p>{__('Find integration documentation')}</p>
            <a href="https://docs.licensespring.com/integrations/salesforce" target="_blank" rel="noopener noreferrer">{__('here')}</a>
          </div>
          <Notice size="sm" theme="default" title={__('Integration has not been activated.')} />
          <Button
            theme="info"
            size="sm"
            onClick={() => setFormDisplay(true)}
          >
            {__('Activate')}
          </Button>
        </div>
        {isFormDisplayed && (
          <HubSpotInit
            closeCb={() => setFormDisplay(false)}
            hubSpotIntegration={hubSpotIntegration}
          />
        )}
      </div>
    );
  }

  return (
    <div className="Integrations">
      <div className="row">
        <HubSpotLogo width="120px" height="60px" />
        <div className="docs-link">
          <p>{__('Find integration documentation')}</p>
          <a href="https://docs.licensespring.com/integrations/salesforce" target="_blank" rel="noopener noreferrer">{__('here')}</a>
        </div>
        <Notice size="sm" theme="success" title={__('Integration has been activated.')} />
        <DescriptionTable
          details={[
            {
              label: __('Management API key'),
              value: maskString(get(hubSpotIntegration, 'data.licenseSpringApiKey')),
            },
          ]}
        />
        <Button
          theme="error"
          size="sm"
          loading={isDeactivating}
          disabled={isDeactivating}
          onClick={handleDeactivate}
        >
          {__('Deactivate')}
        </Button>
      </div>
    </div>
  );
};

HubSpot.propTypes = {
  integrations: PropTypes.array,
};

HubSpot.defaultProps = {
  integrations: [],
};

export default HubSpot;
