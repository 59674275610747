import React from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { isFeatureEnabled, isFeatureAvailable } from 'shared/helpers';
import { ContentLoader } from 'shared/components';
import { platformFeatures } from 'shared/constants';
import { getAccountIntegrations } from 'src/company/actions';
import Salesforce from './Salesforce';
import HubSpot from './HubSpot';
import FastSpring from './FastSpring';
import Stripe from './Stripe';
import './styles.scss';

const Integrations = () => {
  const dispatch = useDispatch();

  const integrations = useSelector(state => get(state, 'company.accountIntegrations')) || [];
  const loadingAccountIntegrations = useSelector(state => get(state, 'company.loadingAccountIntegrations'));
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const companyID = get(companyDetails, 'id');
  const isSalesforceEnabled = isFeatureEnabled(platformFeatures.integrations_salesforce) && isFeatureAvailable(platformFeatures.integrations_salesforce);
  const isHubSpotEnabled = isFeatureEnabled(platformFeatures.integrations_hubspot) && isFeatureAvailable(platformFeatures.integrations_hubspot);

  if (loadingAccountIntegrations) {
    return <ContentLoader text={__('Getting integrations details')} />;
  }

  return (
    <div className="Integrations">
      <FastSpring
        integrations={integrations}
        refreshIntegration={() => dispatch(getAccountIntegrations(companyID))}
      />
      <Stripe
        integrations={integrations}
        refreshIntegration={() => dispatch(getAccountIntegrations(companyID))}
      />
      {isSalesforceEnabled && (
        <Salesforce integrations={integrations} />
      )}
      {isHubSpotEnabled && (
        <HubSpot integrations={integrations} />
      )}
    </div>
  );
};

export default Integrations;
