import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  Button,
  ConfirmationPopup,
  IconCheckmark,
  IconDisabled,
  List,
  Notification,
  StatusCard,
  TooltipHeader,
  PermissionMissingNotificationTitle,
  SelectedFeaturesTags,
} from 'shared/components';
import {
  displayValue,
  formatDate,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  sendErrorReport,
  checkUserPermission,
} from 'shared/helpers';
import {
  defaultDateFormat,
  errorMsg,
  platformFeatures,
  userPermissions,
} from 'shared/constants';
import {
  checkCompanyConfigField,
} from 'shared/companyConfig';
import { disableLicenses } from 'src/order/actions';
import { mapLicenses } from './helpers';

const LicensesList = ({
  order,
  refetchOrder,
}) => {
  const canManageOrders = checkUserPermission(userPermissions.orders_write);
  const history = useHistory();
  const orderID = get(order, 'id');
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const products = useSelector(state => get(state, 'products.list'));
  const orderLicenses = mapLicenses(get(order, 'order_items') || [], products);
  const isBundle = get(order, 'type') === 'bundle';

  const [disablingLicenses, setDisablingLicenses] = useState(false);
  const [disableBulkLicensePopupDisplayed, setDisableBulkLicensePopupDisplay] = useState(false);

  const handleNewOrder = () => {
    if (products.length === 0) {
      Notification('error', __('Company has no products'), __('Add products to company before creating new order'));
      return false;
    }
    history.push(`/${companyID}/licenses/issue-licenses/${orderID}`);
    return true;
  };

  const redirectToLicensePage = (rowData) => {
    const licenseId = get(rowData, 'original.id');
    history.push(`/${companyID}/orders/${orderID}/${licenseId}`);
  };

  const handleLicensesDisable = () => {
    const licenseIds = orderLicenses.map(o => o.id);
    setDisablingLicenses(true);

    disableLicenses(companyID, licenseIds)
      .then(() => {
        refetchOrder();
        setDisablingLicenses(false);
        setDisableBulkLicensePopupDisplay(false);
        Notification('success', __('Licenses succesfully disabled'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot bulk disable licenses', licenseIds);
        setDisablingLicenses(false);
        Notification('error', __('Error occured'), __('Your changes were not saved'));
      });
  };

  const handleManageOrderClick = (cb) => {
    if (!canManageOrders) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.orders_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  const bundleLicenses = orderLicenses.filter(l => l.is_bundle);

  return (
    <div className="LicensesList licenses-table">
      <div className="list-header">
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_add_license)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_add_license)}
            notEnabledMessage={getDisabledMessage()}
            onClick={handleNewOrder}
            theme="info"
            size="sm"
          >
            {__('Issue new Licenses')}
          </Button>
        </div>
        <div>
          <Button
            theme="error"
            size="sm"
            onClick={() => handleManageOrderClick(setDisableBulkLicensePopupDisplay)}
          >
            {__('Disable all licenses')}
          </Button>
        </div>
      </div>
      <List
        clickable
        columns={[
          {
            id: 'license_key',
            Header: `${__('License Key')}/${__('User')}`,
            width: 250,
            sortable: false,
            Cell: (rowData) => {
              if (isBundle) return '-';
              const licenseProduct = products.find(p => p.product_name === get(rowData, 'original.product'));
              const isUserAuth = get(licenseProduct, 'authorization_method') === 'user';
              if (isUserAuth) {
                const licenseUsers = get(rowData, 'original.license_users') || [];
                const usersNum = licenseUsers.length;
                if (usersNum > 0) {
                  const firstUser = get(licenseUsers, '[0].true_email');
                  const isSingleUser = usersNum === 1;
                  if (isSingleUser) {
                    return `${firstUser}`;
                  }
                  return `${firstUser} (+${usersNum - 1})`;
                }
                return __(errorMsg.notAssigned);
              }

              const licenseKey = get(rowData, 'original.license_key');
              if (licenseKey) {
                return displayValue(licenseKey);
              }
              return '-';
            },
          },
          {
            accessor: 'license_type',
            Header: __('License Type'),
            show: !isBundle,
          },
          {
            accessor: 'validity_period',
            Header: () => TooltipHeader(__('Expiration Date')),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData => formatDate(cellData.value, defaultDateFormat),
            show: !isBundle,
          },
          {
            accessor: 'product',
            Header: __('Product'),
            width: 150,
          },
          {
            accessor: 'created_at',
            Header: __('Created on'),
            Cell: cellData => formatDate(cellData.value),
          },
          {
            accessor: 'status',
            className: 'text-center',
            Header: __('Status'),
            headerClassName: 'text-center',
            Cell: cellInfo => (
              <StatusCard
                status={cellInfo.value === 'Active' ? 'success' : 'error'}
                text={__(cellInfo.value)}
              />
            ),
            show: !isBundle,
          },
          {
            accessor: 'product_features',
            Header: __('Product features'),
            headerClassName: 'text-center',
            Cell: (rowData) => {
              if (isBundle) return '-';
              return <SelectedFeaturesTags features={get(rowData, 'value') || []} />;
            },
            show: true,
            sortable: false,
          },
          {
            accessor: 'max_activations',
            Header: __('Max activations'),
            Cell: (rowData) => {
              const isUnlimited = get(rowData, 'original.allow_unlimited_activations');
              if (isUnlimited) {
                return __('Unlimited');
              }
              return displayValue(rowData.value);
            },
          },
          {
            accessor: 'is_trial',
            Header: __('Is trial'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData => (cellData.value ? <IconCheckmark color="#10ac84" height="14px" /> : <IconDisabled color="#aaa" height="14px" />),
            show: checkCompanyConfigField(companyID, 'isTrial') && !isBundle,
          },
          {
            accessor: 'is_bundle',
            Header: __('Is bundle'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData => (cellData.value ? <IconCheckmark color="#10ac84" height="14px" /> : <IconDisabled color="#aaa" height="14px" />),
            width: 100,
            show: isBundle,
          },
        ]}
        data={isBundle ? bundleLicenses : orderLicenses}
        handleClick={rowData => redirectToLicensePage(rowData)}
        minRows={1}
        pageSize={20}
        sortable={false}
      />
      {disableBulkLicensePopupDisplayed && (
        <ConfirmationPopup
          closeCb={() => setDisableBulkLicensePopupDisplay(false)}
          confirmCb={handleLicensesDisable}
          confirmText={__('Disable')}
          disabled={disablingLicenses}
          theme="error"
          title={__('Are you sure you want to disable all licenses from this order?')}
        />
      )}
    </div>
  );
};

LicensesList.propTypes = {
  order: PropTypes.object.isRequired,
  refetchOrder: PropTypes.func.isRequired,
};

export default LicensesList;
